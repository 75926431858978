import React from "react";
import Logo from "../img/checking1.png";

const Modal = ({ showModal, closeModal, children, clinic_id }) => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <>
      {showModal && (
        <div className="fixed inset-0 bg-white flex items-center justify-center">
          <div className="flex flex-col gap-4 justify-center items-center bg-white p-8 rounded-lg">
            <div className="flex flex-col items-center justify-center">
              <img
                src={Logo}
                alt="logo"
                className="w-[15rem] -mt-8 rounded-lg"
              />
              {/* {children} */}
            </div>
            <button
              onClick={handleReload}
              className="w-full mt-4 bg-blue-700 z-10 text-white px-4 py-2 rounded-full"
            >
              Return to Website
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
